/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
// icons
import { Search } from "@mui/icons-material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Divider, IconButton, Select, Skeleton } from "@mui/joy";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Order } from "../../helpers/TableHelpers";
import { CaseFile } from "../../models/CaseFile";
import { MigrationStatus, MigrationStatusColors, MigrationStatusLabels } from "../../models/MigrationStatus";
import CasesRepository from "../../repositories/CasesRepository";
import { ClearableSelect } from "../common/ClearableSelect";
import FileExtensionsInput from "../common/FileExtensionsInput";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";

interface CasesTableProps {
    caseId: string;
  }

const CaseFilesTable: React.FC<CasesTableProps> = ({
    caseId
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<string>("status");
  const [statusFilter, setStatusFilter] = React.useState<
    string | null
  >(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [caseFiles, setCaseFiles] = useState<
    CaseFile[]
  >([]);
  const [rows, setRows] = useState(0);
  const [extensions, setExtensions] = useState<string[]>([]);
  const [extensionsSearch, setExtensionsSearch] = useState<string[]>([]);
  const casesRepository = new CasesRepository();
  const caseFilesQuery = useQuery(
    ["caseFiles", caseId, page, rowsPerPage, statusFilter, sort, order, extensionsSearch],
    async () => {
      const cases = await casesRepository.getCaseFilesForCase(
        caseId,
        {
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        extension: extensionsSearch ? extensionsSearch : null,
        pageNumber: page + 1, 
        pageSize: rowsPerPage});
      return cases;
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    },
  );
  useEffect(() => {
    if (caseFilesQuery.data?.data) {
      setCaseFiles(caseFilesQuery.data?.data);
      setRows(caseFilesQuery.data.paging?.totalCount ?? 0)
    }
  }, [caseFilesQuery]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const changeSort = (newSort: string) => {
    setPage(0);
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows
      : Math.min(rows, (page + 1) * rowsPerPage);
  };
  
  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }
  const { isError, isFetching } = caseFilesQuery;
  
  const renderFilters = () => (
    <React.Fragment>
        <FormControl size="sm">
          <FormLabel>Status</FormLabel>
          <ClearableSelect
            value={statusFilter}
            color={MigrationStatusColors[statusFilter as MigrationStatus]}
            onChange={(newValue) => {
              setPage(0);
              setStatusFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            {Object.values(MigrationStatus).filter(v => v !== MigrationStatus.InProgress)
            .map((status) => {
            const migrationStatus = status as MigrationStatus;
            return (
              <Option value={migrationStatus} color={MigrationStatusColors[migrationStatus]}>
                {MigrationStatusLabels[migrationStatus]}
              </Option>
            );
          })}
          </ClearableSelect>
        </FormControl>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Search for files with extension</FormLabel>
            <FileExtensionsInput 
                inputLabel={extensions.length > 0 ? "" : "Extensions"} 
                extensions={extensions} 
                onExtensionsChange={(newExtensions) => {setExtensions(newExtensions)}}
                usePlaceholderAsLabel={true}
                maxExtensionsPerLine={null}
                endDecorator={<React.Fragment><Divider/><Button onClick={() => {setExtensionsSearch(extensions)}} variant="plain" startDecorator={<Search/>}>Search</Button></React.Fragment>}        
          />
          </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="CaseFilesTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>
                <th
                  style={{
                    width: "10%",
                    padding: "12px 6px",
                  }}
                >
                  File ID
                </th>
                <th
                  style={{ width: "10%", padding: "12px 6px" }}
                >
                  <SortableTableColumnHeader 
                    label={"Status"} 
                    sortProperty={"status"} 
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                </th>
                <th
                  style={{ width: "50%", padding: "12px 6px" }}
                >
                  File Path / Source Api File ID
                </th>
                <th
                  style={{ width: "15%", padding: "12px 6px" }}
                >
                  <SortableTableColumnHeader 
                    label={"Size (MB)"} 
                    sortProperty={"fileSize"} 
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                </th>
                
                <th
                  style={{ width: "10%", padding: "12px 6px" }}
                >
                 Extension
                </th>
              </tr>
            </thead>
            <tbody>
              {caseFiles.map((caseFile) => {
                return (
                  <tr
                    key={caseFile.id}
                  >
                    <td style={{ textAlign: "center"}}></td>
                    <td>
                      <Typography level="body-sm">
                        {caseFile.id}
                      </Typography>
                    </td>
                    <td>
                      <MigrationStatusChip status={caseFile.status}/>
                    </td>
                    <td>
                    <Typography level="body-sm" sx={{wordBreak: "break-word"}}>
                        {caseFile.filePath}
                      </Typography>
                    </td>
                    <td>
                    <Chip
                        variant="soft"
                        size="sm"
                        color="neutral"
                      >
                        {(caseFile.fileSizeInMb).toFixed(2)}
                      </Chip>
                    </td>
                    <td>
                    <Chip
                        variant="soft"
                        size="sm"
                        color="primary"
                      >
                        {(caseFile.extension ?? '-')}
                      </Chip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
          <tr>
            <td colSpan={7}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <FormControl orientation="horizontal" size="sm">
                  <FormLabel>Rows per page:</FormLabel>
                  <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                    <Option value={100}>100</Option>
                    <Option value={500}>500</Option>
                    <Option value={1000}>1000</Option>
                  </Select>
                </FormControl>
                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: rows === 0 ? 0 : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: rows === -1 ? -1 : rows,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={
                      rows !== -1
                        ? page >= Math.ceil(rows / rowsPerPage) - 1
                        : false
                    }
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
          </Table>
        </Skeleton>
      </Sheet>
    </React.Fragment>
  );
};

export default CaseFilesTable;
